export const environment = {
  production: true,
  base: location.origin,
  api: ' https://gissv2-3525904.eiconbrasil.com.br',
  appId: '1d1164ee-bcd3-e2eb-4d58-414a9bd6a90c',
  versao: 'v2.00.M.20210204.01',
  frontGissV2: 'https://jundiai.giss.com.br/portal/#/login-portal',
  gissAjudaV2: 'https://jundiai.giss.com.br/giss-ajuda/',
  redirectUrl: '/'
};
